import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import Sticky from 'react-stickynode';
import { foodDeliveryTheme } from 'common/theme/foodDelivery';
import { ResetCSS } from 'common/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
} from '../containers/FoodDelivery/foodDelivery.style';

import Navbar from '../containers/FoodDelivery/Navbar';
import Testimonials from '../containers/FoodDelivery/Testimonials';
import Banner from '../containers/FoodDelivery/Banner';
import PopularCategory from '../containers/FoodDelivery/PopularCategory';
import HowWorks from '../containers/FoodDelivery/HowWorks';
import ProductDelivery from '../containers/FoodDelivery/ProductDelivery';
import QualityFeatures from '../containers/FoodDelivery/QualityFeatures';
import Gallery from '../containers/FoodDelivery/Gallery';
import CallToAction from '../containers/FoodDelivery/CallToAction';
import Footer from '../containers/FoodDelivery/Footer';
import Seo from '../components/seo';
import CookieConsent from 'react-cookie-consent';

const FoodDelivery = () => {
  return (
    <ThemeProvider theme={foodDeliveryTheme}>
      <Fragment>
        <Seo title="Great NRI | One Stop for any NRI services in India" />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <Banner />
          <PopularCategory />
          <HowWorks />
          <ProductDelivery /> 
          <QualityFeatures />
          {/* <Testimonials /> */}
          <Gallery />
          <CallToAction />
          <Footer />
          <CookieConsent buttonText="Accept" buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
  expires={150}>
          We use cookies to enhance your browsing experience and provide personalized content on our website. By clicking 'Accept' below, you consent to the use of cookies. <br/>
          Cookies are small text files that are stored on your computer or device. They help us remember your preferences, analyze website traffic, and improve our services. We may also share information about your use of our site with our analytics and advertising partners. <br/>
          By continuing to use our website without changing your cookie settings, you agree to our use of cookies as described in our Privacy Policy.
          </CookieConsent>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
export default FoodDelivery;
