import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Container from 'common/components/UI/Container';
import Heading from 'common/components/Heading';
import Text from 'common/components/Text';
import Image from 'common/components/Image';
import GatsbyImage from 'common/components/GatsbyImage';
import Box from 'common/components/Box';
import BannerWrapper from './banner.style';
import { arrowRight } from 'react-icons-kit/feather/arrowRight';
import { Icon } from 'react-icons-kit';

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      foodDeliveryJson {
        BANNER_DATA {
          sectionImage {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
          }
          title
          text
          tagLine
          buttons {
            icon {
              publicURL
            }
            title
            text
            link
          }
        }
      }
    }
  `);
  const { sectionImage, title, text, tagLine, buttons } =
    Data.foodDeliveryJson.BANNER_DATA;
  return (
    <BannerWrapper id="banner_section">
      <Container>
        <Box className="content">
          <Heading as="h2" content={title} />
          <Text as="p" className="paragraph" content={text} />
          <form action="#" className="bannerForm">
          <Link className="callButton" to={"/quote"}>
              Get your estimate  <Icon size={14} icon={arrowRight} />
            </Link>
          </form>
          
          {/* <Text as="p" className="tagLine" content={tagLine} /> */}
          <Text as="p" className="tagLine" content="We are excited to offer our services in " />
          <Text as="p" className="tagLine" content="Hyderabad .. and expanding to more locations soon" />
          {/* TODO: uncomment following to display app download links */}
          {/* <Box className="buttonWrap">
            {buttons.map(({ icon, text, title, link }, index) => (
              <Link
                className="bannerAppBtn"
                to={link}
                key={`banner-button-${index}`}
              >
                <Image
                  src={icon.publicURL}
                  width="30"
                  height="30"
                  alt={title}
                />
                <Box className="btnContent">
                  <Text as="span" content={text} />
                  <Text as="p" content={title} />
                </Box>
              </Link>
            ))}
          </Box> */}
        </Box>
        <Box className="image">
          <Fade right>
            <GatsbyImage
              src={
                (sectionImage !== null) | undefined
                  ? sectionImage.childImageSharp.gatsbyImageData
                  : {}
              }
              alt={title}
            />
          </Fade>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
